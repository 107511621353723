import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { format, getPayloadObject, getTelemetryAttributes, ITelemetryContent, onTelemetryClick } from '@msdyn365-commerce-modules/utilities';
import { IComponent, IComponentProps, ICoreContext, IGridSettings, IImageData, IImageSettings, Image, msdyn365Commerce } from '@msdyn365-commerce/core';
import { ProductPrice, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import { PriceComponent, RatingComponent } from '../index';

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
    allowBack?: boolean;
    telemetryContent?: ITelemetryContent;
}

export interface IProductComponent extends IComponent<IProductComponentProps> { }

const PriceComponentActions = {
};

const ProductCard: React.FC<IProductComponentProps> = ({
    data,
    context,
    imageSettings,
    savingsText,
    freePriceText,
    originalPriceText,
    currentPriceText,
    ratingAriaLabel,
    allowBack,
    typeName,
    id,
    telemetryContent
}) => {
    const product = data.product;
    if (!product) {
        return null;
    }

    let productUrl = getProductPageUrlSync(product.Name || '', product.RecordId, context && context.actionContext, undefined);
    if(allowBack) {
        productUrl = updateProductUrl(productUrl, context);
    }

    // Construct telemetry attribute to render
    const payLoad = getPayloadObject('click', telemetryContent!, '', product.RecordId.toString());

    const attribute = getTelemetryAttributes(telemetryContent!, payLoad);

    return (
        <a href={productUrl} onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)} aria-label={renderLabel(product.Name, context.cultureFormatter.formatCurrency(product.Price), product.AverageRating, ratingAriaLabel)} className='msc-product' {...attribute}>
            <div className='msc-product__image'>
            {renderProductPlacementImage(imageSettings, context.request.gridSettings, product.PrimaryImageUrl, product.Name)}
            </div>
            <div className='msc-product__details'>
                <h4 className='msc-product__title'>{product.Name}</h4>
                {renderPrice(context, typeName, id, product.BasePrice, product.Price, savingsText, freePriceText, originalPriceText, currentPriceText)}
                {renderDescription(product.Description)}
                {!context.app.config.hideRating && renderRating(context, typeName, id, product.AverageRating, product.TotalRatings, ratingAriaLabel)}
            </div>
        </a>
    );
};

function renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
    name = name || '';
    price = price || '';
    return (`${name} ${price} ${getRatingAriaLabel(rating, ratingAriaLabel)}`);
}

function renderDescription(description?: string): JSX.Element | null {
    return <p className='msc-product__text'>{description}</p>;
}

function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
    if (rating && ratingAriaLabel) {
    const roundedRating = rating.toFixed(2);
    return format(ratingAriaLabel || '', roundedRating, '5');
    }
    return '';
}

function updateProductUrl(productUrl: string, context:ICoreContext): string {
    const srcUrl = new URL(productUrl, context.request.apiSettings.baseUrl);
    const queryString = `back=true` ;
    if (srcUrl.search) {
            srcUrl.search += `&${queryString}`;
        } else {
            srcUrl.search += queryString;
        }

    const updatedUrl = new URL(srcUrl.href);
    return updatedUrl.pathname + srcUrl.search;
}

function renderRating(context: ICoreContext, typeName: string, id: string, avgRating?: number, totalRatings?: number, ariaLabel?: string): JSX.Element | null {
    if (!avgRating) {
        return null;
    }

    const numRatings = totalRatings && totalRatings.toString() || undefined;
    const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);

    return (
        <RatingComponent
            context={context}
            id={id}
            typeName={typeName}
            avgRating={avgRating}
            ratingCount={numRatings}
            readOnly={true}
            ariaLabel={ratingAriaLabel}
            data={{}}
        />
    );
}

function renderPrice(context: ICoreContext, typeName: string, id: string, basePrice?: number, adjustedPrice?: number, savingsText?: string, freePriceText?: string, originalPriceText?: string, currentPriceText?: string): JSX.Element | null {
    const price: ProductPrice = {
        BasePrice: basePrice,
        AdjustedPrice: adjustedPrice,
        CustomerContextualPrice: adjustedPrice
    };

    return (
        <PriceComponent
            context={context}
            id={id}
            typeName={typeName}
            data={{ price: price }}
            savingsText={savingsText}
            freePriceText={freePriceText}
            originalPriceText={originalPriceText}
        />
    );
}

function renderProductPlacementImage(imageSettings?: IImageSettings, gridSettings?: IGridSettings, imageUrl?: string, altText?: string): JSX.Element | null {
    if (!imageUrl || !gridSettings || !imageSettings) {
        return null;
    }
    const img: IImageData = {
        src: imageUrl,
        altText: altText ? altText : ''
    };
    const imageProps = {
        gridSettings: gridSettings,
        imageSettings: imageSettings
    };
    return (
        <Image {...img} {...imageProps} loadFailureBehavior='empty' />
    );
}

// @ts-ignore
export const ProductComponent: React.FunctionComponent<IProductComponentProps> = msdyn365Commerce.createComponent<IProductComponent>(
    'Product',
    { component: ProductCard, ...PriceComponentActions }
);
import { OrderTemplate } from '@msdyn365-commerce-modules/retail-actions';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { IComponentProps } from '@msdyn365-commerce/core';
import { ProductList } from '@msdyn365-commerce/retail-proxy';
import classnames from 'classnames';
import React, { useState } from 'react';
import { IOrderTemplateNameDialogResources, OrderTemplateNameDialog } from '../order-template-name-dialog/order-template-name-dialog.component';
import { OrderTemplateComponent } from '../order-templates-list/order-template-list';

export interface IAddToOrderTemplateDialogData {
    orderTemplates: OrderTemplate[];
}

export interface IAddToOrderTemplateDialogResources {
    addToOrderTemplateHeader: string;

    noOrderTemplatesMessage: string;
    noOrderTemplatesDescription: string;

    createAnOrderTemplateButtonText: string;
    createNewOrderTemplateButtonText: string;
    cancelOrderTemplateCreationButtonText: string;

    selectTemplatesText: string;
    addToTemplateButtonText: string;
    lineItemsText: string;
}

export interface IAddToOrderTemplateDialogProps extends IComponentProps<IAddToOrderTemplateDialogData> {
    className: string;

    addToOrderTemplateDialogResources: IAddToOrderTemplateDialogResources;
    createOrderTemplateDialogResources: IOrderTemplateNameDialogResources;

    modalOpen: boolean | undefined;
    setModalOpen(newValue: boolean): void;
    onOrderTemplateSelected(orderTemplate: ProductList): Promise<void>;
}

const renderAddToOrderTemplatesWhenEmptyOrderTemplatesDialog = (
    dialogProps: IAddToOrderTemplateDialogProps,
    className: string,
    closeDialog: () => void,
    showOrderTemplateNameDialog: () => void) => {

    const {
        addToOrderTemplateDialogResources,
        modalOpen
    } = dialogProps;
    return (
        <React.Fragment>
            <Modal isOpen={modalOpen} className={`${className}`}>
                <ModalHeader className={`${className}__dialog__header`} toggle={closeDialog}>
                    <span>{addToOrderTemplateDialogResources.addToOrderTemplateHeader}</span>
                </ModalHeader>
                <ModalBody className={`${className}__dialog__body`}>
                    <div className={`${className}__dialog__content`}>
                        <div className={`${className}__dialog__create-order-template-image-container`}>
                            <div
                                className={classnames('msc-create-order-template-icon', `${className}__dialog__create-order-template-image`)}
                            />
                        </div>
                        <div className={`${className}__dialog__no-order-templates`}>{addToOrderTemplateDialogResources.noOrderTemplatesMessage}</div>
                        <div className={`${className}__dialog__no-order-templates-description`}>{addToOrderTemplateDialogResources.noOrderTemplatesDescription}</div>
                    </div>
                </ModalBody>
                <ModalFooter
                    className={`${className}__dialog__footer`}
                >
                    <Button
                        className={`${className}__dialog__create-order-template-button`}
                        title={addToOrderTemplateDialogResources.createAnOrderTemplateButtonText}
                        onClick={showOrderTemplateNameDialog}
                    >
                        {addToOrderTemplateDialogResources.createAnOrderTemplateButtonText}
                    </Button>
                    <Button
                        className={`${className}__dialog__cancel-button`}
                        title={addToOrderTemplateDialogResources.cancelOrderTemplateCreationButtonText}
                        onClick={closeDialog}
                    >
                        {addToOrderTemplateDialogResources.cancelOrderTemplateCreationButtonText}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

const formatBold = (text: string, boldText: string) => {
    const patternToFind = '{0}';
    return (
        <>{text.slice(0, text.indexOf(patternToFind))}<b>{boldText}</b>{text.slice(text.indexOf(patternToFind) + patternToFind.length)}</>
    );
};

const renderAddToOrderTemplatesForExistingOrderTemplatesDialog = (
    dialogProps: IAddToOrderTemplateDialogProps,
    className: string,
    closeDialog: () => void,
    showOrderTemplateNameDialog: () => void,
    onAddToOrderTemplateButtonClick: (orderTemplate: ProductList) => Promise<void>) => {

    const {
        addToOrderTemplateDialogResources,
        modalOpen,
        data: { orderTemplates }
    } = dialogProps;
    return (
        <React.Fragment>
            <Modal isOpen={modalOpen} className={`${className}`}>
                <ModalHeader className={`${className}__dialog__header`} toggle={closeDialog}>
                    <span>{addToOrderTemplateDialogResources.addToOrderTemplateHeader}</span>
                </ModalHeader>
                <ModalBody className={`${className}__dialog__body`}>
                    <div className={`${className}__dialog__content`}>
                        <div className={`${className}__dialog__order-templates-description`}>
                            {formatBold(addToOrderTemplateDialogResources.selectTemplatesText, orderTemplates.length.toString())}
                        </div>
                        <div className={`${className}__dialog__order-templates-container`}>
                            <div
                                role='button'
                                className={
                                    classnames(
                                        `${className}__dialog__order-templates-line__container`,
                                        `${className}__dialog__order-templates-line__container__create-new-line`)
                                }
                                onClick={showOrderTemplateNameDialog}
                            >
                                <span
                                    className={classnames(`${className}__dialog__order-templates-line__container__create-new-line__image`, 'msc-create-order-template-icon')}
                                />
                                <span className={`${className}__dialog__order-templates-line__container__create-new-line__text`}>{addToOrderTemplateDialogResources.createNewOrderTemplateButtonText}</span>
                            </div>
                            {
                                orderTemplates.map((orderTemplate, index) => {
                                    return (
                                        <OrderTemplateComponent
                                            key={index}
                                            className={`${className}__dialog__order-templates-line`}
                                            orderTemplate={orderTemplate}
                                            context={dialogProps.context}
                                            actionButtonText={addToOrderTemplateDialogResources.addToTemplateButtonText}
                                            lineItemsText={addToOrderTemplateDialogResources.lineItemsText}
                                            orderTemplateAction={onAddToOrderTemplateButtonClick}
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export const AddToOrderTemplateDialog = (dialogProps: IAddToOrderTemplateDialogProps) => {
    const {
        setModalOpen, data: { orderTemplates }, className
    } = dialogProps;

    const closeDialog = () => {
        setModalOpen(false);
    };

    const [createOrderTemplateDialogOpen, setOrderTemplateNameDialogOpen] = useState(false);
    const showOrderTemplateNameDialog = () => {
        setModalOpen(false);
        setTimeout(() => {
            setOrderTemplateNameDialogOpen(true);
        }, 500); // tslint:disable-line:align
    };
    const onCreateOrderTemplateCancel = () => {
        setTimeout(() => {
            setModalOpen(true);
        }, 500); // tslint:disable-line:align
    };

    let addToOrderTemplateDialog: JSX.Element;
    if (orderTemplates.length !== 0) {
        addToOrderTemplateDialog = renderAddToOrderTemplatesForExistingOrderTemplatesDialog(
            dialogProps,
            `${className}__existing`,
            closeDialog,
            showOrderTemplateNameDialog,
            dialogProps.onOrderTemplateSelected);
    } else {
        addToOrderTemplateDialog = renderAddToOrderTemplatesWhenEmptyOrderTemplatesDialog(
            dialogProps,
            `${className}__empty`,
            closeDialog,
            showOrderTemplateNameDialog);
    }

    return (
        <>
            {addToOrderTemplateDialog}
            <OrderTemplateNameDialog
                className={'msc-order-template-name-dialog'}
                modalOpen={createOrderTemplateDialogOpen}
                isUpdateDialog={false}
                setModalOpen={setOrderTemplateNameDialogOpen}
                context={dialogProps.context}
                id={dialogProps.id}
                data={{}}
                typeName={dialogProps.typeName}
                dialogStrings={dialogProps.createOrderTemplateDialogResources}
                onCancel={onCreateOrderTemplateCancel}
                onSuccess={dialogProps.onOrderTemplateSelected}
            />
        </>
    );
};
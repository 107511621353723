import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { IComponentProps } from '@msdyn365-commerce/core';
import React from 'react';

export interface IDuplicateItemsWhenAddingToOrderTemplateDialogResources {
    duplicatedProductsHeader: string;

    duplicatedProductsDescription: string;

    updateQuantityButtonText: string;
    cancelDuplicateItemsButtonText: string;
}

export interface IDuplicateItemsWhenAddingToOrderTemplateDialogProps extends IComponentProps {
    className: string;

    dialogStrings: IDuplicateItemsWhenAddingToOrderTemplateDialogResources;

    modalOpen: boolean | undefined;

    executeOverrideQuantity(): Promise<void>;
    onCancel(): void;
}

export const DuplicateItemsWhenAddingToOrderTemplateDialog = (dialogProps: IDuplicateItemsWhenAddingToOrderTemplateDialogProps) => {
    const {
        dialogStrings,
        className,
        modalOpen,
        executeOverrideQuantity
    } = dialogProps;

    return (
        <Modal isOpen={modalOpen} className={`${className}`}>
            <ModalHeader className={`${className}__dialog__header`} toggle={dialogProps.onCancel}>
                <span>{dialogStrings.duplicatedProductsHeader}</span>
            </ModalHeader>
            <ModalBody className={`${className}__dialog__body`}>
                <div className={`${className}__dialog__content`}>
                    <div className={`${className}__dialog__description`}>{dialogStrings.duplicatedProductsDescription}</div>
                </div>
            </ModalBody>
            <ModalFooter
                className={`${className}__dialog__footer`}
            >
                <Button
                    className={`${className}__dialog__update-quantity-button`}
                    title={dialogStrings.updateQuantityButtonText}
                    onClick={executeOverrideQuantity}
                >
                    {dialogStrings.updateQuantityButtonText}
                </Button>
                <Button
                    className={`${className}__dialog__cancel-button`}
                    title={dialogStrings.cancelDuplicateItemsButtonText}
                    onClick={dialogProps.onCancel}
                >
                    {dialogStrings.cancelDuplicateItemsButtonText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};